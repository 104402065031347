input#approvalDateFilter,
select#stageFilter {
    border-color: #ced4da;
    font-weight: 300;
    @include border-radius(2px);
}

label[for="approvalDateFilter"],
label[for="stageFilter"] {
    color: lighten($gray-text, 25%);
    padding-bottom: 10px;
}

.search-bar {
    @include border-radius(2px);
    width: 100%;
    margin: 0;
    padding: 20px 0;
    background-color: $light-gray;
}