.chart {
  vertical-align: middle;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: inline-block;

}

canvas {
  height: 100%;
}

.panel-wrapper {
  width: 100%;
  margin: 0;
  height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;
}