// Probability Form
.probability-form-container {
	padding: 1rem 2rem;
	height: 840px;

	fieldset .form-group {
		& textarea {
			@include font-size-and-weight(1rem, 300);
		}
	}

	fieldset p strong {
		@include font-size-and-weight (20px, bold);
	}

	fieldset p {
		@include font-size-and-weight (16px, 300);
		margin-bottom: 20px;
	}

	fieldset p:first-child {
        padding-top: 1.5rem;
        margin-bottom: 10px;
    }

	fieldset:last-of-type {
		color: white;
		background: $questionBg;
		border-radius: 3px;
		padding: 0 10px 10px 10px;
		border: 1px solid #7f8dad;
		margin: 60px 0 20px 0;
		
		& p {
			padding-top: 10px;
		}

		& p:last-of-type {
			font-size: 18px;
			padding-top: 0px;
		}
		
		& p strong {
			font-size: 24px;
			color: white;
		}
	}

	& p:first-child {
		font-size: 20px;
	}

	& label {
		font-weight: lighter;
	}
}

// Impact Form
.impact-form-container {
	height: 520px;
	padding: 2rem;
	
	&--sector-selection {
		display: flex;
		margin-bottom: 20px;
		vertical-align: middle;
	}

	label {
		@include font-size-and-weight (20px, bold)
	}

	& select.custom-select {
		width: 30%;
	}

	fieldset {
		padding-top: 30px;
		
		&:first-child {
			padding-top: 0;
		}
		
		& p:first-child {
			@include font-size-and-weight(20px, 800);
			margin-bottom: 5px;
		}

		& p:nth-child(2) {
			font-weight: 300;
			margin-bottom: 20px;
		}

		& .form-group label {
			font-weight: 500;
			margin-bottom: 10px;
		}
	}

	& .form-check-label {
		@include font-size-and-weight (15px, 300);
	}
}

.impact-form-container fieldset:last-child {
	margin-bottom: 30px;
}

#impact-form-modal .modal-lg{
	max-width: 995px;
}


.toggle input + label {
	padding: .75rem 2.5rem;
}


// Event Create Form
#event-create-form {
	& .modal-body {
		padding: 40px 3rem 0 3rem;
	}

	& .form-group {
		display: flow-root;

		& label {
			@include form-inputs-config(190px, bolder);
			text-align: right;
			float: left;
			margin: 0 20px 0 0;
			padding: 7px;
		}

		& input {
			@include form-inputs-config(25%, 300);
		}

		& textarea {
			@include form-inputs-config(70%, 300);
		}
		
		& select {
			@include form-inputs-config(70%, 300);
		}
	}
}

// Event Edit Form
#event-edit-form {
	& .modal-body {
		padding-top: 40px;
	}

	& .form-group {
		display: flow-root;

		& label {
			@include form-inputs-config(190px, bolder);
			text-align: right;
			float: left;
			margin: 0 20px 0 0;
			padding: 7px;
		}

		& input {
			@include form-inputs-config(23%, 300);
			text-align: center;
		}

		& textarea {
			@include form-inputs-config(70%, 300);
		}

		& select {
			@include form-inputs-config(70%, 300);
		}
	}
}

// User Admin Form
#user-form-modal {
	& .modal-body {
		padding: 20px 3rem;
	}

	& .modal-lg {
		width: 1040px;
	}

	& .form-group {
		display: flow-root;

		& label {
			@include form-inputs-config(190px, bolder);
			text-align: left;
			float: left;
			margin: 0 20px 0 0;
			padding: 7px 7px 7px 0;
		}

		& input { 
			@include form-inputs-config(100%, 300); 
		}

		& select {
			@include form-inputs-config(70%, 300);
		}
	}
}

.survey-box {
	border-bottom: 1px solid #ced4da;
	margin-bottom: 45px;
	&:last-child{
		border-bottom: 0;
		margin-bottom: 0;	
	}
}