@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url('../../assets/webfonts/fa-solid-900.eot');
    src: url('../../assets/webfonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('../../assets/webfonts/fa-solid-900.woff2') format('woff2'),
    url('../../assets/webfonts/fa-solid-900.woff') format('woff'),
    url('../../assets/webfonts/fa-solid-900.ttf') format('truetype'),
    url('../../assets/webfonts/fa-solid-900.svg#fontawesome') format('svg');
  }

  @font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('../../assets/webfonts/fa-regular-400.eot');
    src: url('../../assets/webfonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    url('../../assets/webfonts/fa-regular-400.woff2') format('woff2'),
    url('../../assets/webfonts/fa-regular-400.woff') format('woff'),
    url('../../assets/webfonts/fa-regular-400.ttf') format('truetype'),
    url('../../assets/webfonts/fa-regular-400.svg#fontawesome') format('svg');
  }
  
  // .far {
  //   font-family: 'Font Awesome 5 Free';
  //   font-weight: 400;
  // }
  
  // .fa,
  // .fas {
  //   font-family: 'Font Awesome 5 Free';
  //   font-weight: 900;
  // }