.destroy-user-container {
    background-color: rgba(255, 0, 0, 0.906) !important;
}

.destroy-user-title-class {
    font-family: $font-family-base;
    font-weight: 400;
    color: lighten($gray-text, 15%);
}

.destroy-user-confirm-button-class {
    background-color: $primary !important;
}

.swal2-icon.swal2-warning {
    border-color: $warning;
    color: $warning;
}