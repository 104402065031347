.login {
    padding: 0;
    border-radius: 2px;
    margin: 0;
    position: absolute;
    width: 665px;
    top: 50%;
    transform: translateY(-50%);

    & h3 {
        @include login-h-config ($white, 24px, bold);
        padding: 14px 60px 14px 60px;
        background-color: $secondary;
        border-radius: 2px;
    }

    & h4 {
        @include login-h-config($primary, 21px, 300);
        padding: 10px 20px 10px 10px;
        background-color: $light-gray;
    }

    &--inputs-container {
        padding: 40px 100px 20px 100px;
    }

    & label, .col-form-label {
        text-align: right;
        font-weight: 300;
    }

    &--pass-recover {
        text-align: left !important;
    }

    & .form-group:not(:last-child) {
        margin-bottom: 40px;
    }

}

.invalid-login-txt {
    position: absolute;
    padding-top: 5px;
    color: #ff7474;
}

.server-error {
    background-color: $warning;
    padding: 20px;
    font-weight: bolder;
    color: #fff;
    border-radius: 2px;
}
