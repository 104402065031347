@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'vendors/bootstrap';
@import 'vendors/fa';

@import 'base/typography';
@import 'base/base';
@import 'base/reset';

@import 'components/buttons';
@import 'components/inputs';
@import 'components/options';
@import 'components/swal';

@import 'layout/chart';
@import 'layout/footer';
@import 'layout/forms';
@import 'layout/header';
@import 'layout/login';
@import 'layout/modals';
@import 'layout/navigation';
@import 'layout/search';
@import 'layout/sidebar';
@import 'layout/table';
@import 'layout/graphics';

@import 'pages/home';

@import 'themes/user';
@import 'themes/admin';
