.selected-event {
    background-color: #F5F5F5;
    @include font-size-and-weight($font-size, 300);
    @include srl-border-radius(2px, all);
    width: 100%;
    margin: 0;
}

.selected-event-date {
    border-radius: 0 3px 3px 0;
}