@mixin outlineNone {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
@mixin hideInput { width: 0; height: 0; position: absolute; left: -9999px; }

.toggle {
	margin: 0; box-sizing: border-box;
	font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
	input { @include hideInput; }
	input + label {
		background-color: $inputBg;
		color: white;
			margin: 0; padding: .75rem 1rem; box-sizing: border-box;
			position: relative; display: inline-block;
			font-size: 1rem; line-height: 140%; font-weight: 300; text-align: center;
			box-shadow: 0 0 0 rgba(255,255,255,0);
			transition: 	border-color .15s ease-out,
						color .25s ease-out,
						background-color .15s ease-out,
						box-shadow .15s ease-out;

			&:first-of-type {border-radius: 2px 0 0 2px; border-right: none;}
			&:last-of-type {border-radius: 0 2px 2px 0; border-left: none;}
	}
	input:hover + label {
		border-color: $darkNavy;
		cursor: pointer;
		background-color: darken($primary, 20%);
	}
	input:checked + label {
		background-color: $teal2;
		z-index: 1;
	}
	input:focus + label  { outline: 1px solid transparent; }
	input:active + label { outline: 1px solid transparent; }

  @include media-breakpoint-down(sm) {
    flex-flow: column;
  }
}

