.btn {
    @include srl-border-radius(2px, all);
    background-color: $primary;
    padding: 8px 1rem;
    color: $white;
    transition: .2s all;
    text-align: left;

    @include media-breakpoint-down(sm)  { font-size: 13px; }
    @include media-breakpoint-down(md)    { font-size: 14px; }
    @include media-breakpoint-down(hlg)    { font-size: 15px; }
    @include media-breakpoint-down(hxl)    { font-size: 16px; }

    &--login {
        background-color: $secondary;
    }
    &--login:hover {
        background-color: lighten($secondary, 10%) !important;
    }
    &--login:active {
        box-shadow: 0 0px rgb(41, 41, 41);
    }

    &--unstyled {
        background: none;
        color: inherit;
        border: none;
        width: 100%;
        padding: 0;
        @include media-breakpoint-down(sm)  { font-size: 10px; }
        @include media-breakpoint-down(md)    { font-size: 12px; }
        @include media-breakpoint-down(hlg)    { font-size: 14px; }
        @include media-breakpoint-down(hxl)    { font-size: 16px; }
        font-weight: 400 !important;
        cursor: pointer;
        outline: 0;
    }
    &--unstyled:hover {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: 0;
    }
    &--unstyled:active {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: 0;
    }

    &--unstyled:focus {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: 0;
    }

    &:hover {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }

    &:active {
        box-shadow: 0 0px rgb(41, 41, 41);
    }
}

button.btn--unstyled.nav-link.text-white {
    line-height: 1;
}

button.btn--unstyled.btn-tooltip {
    font-weight: 300 !important;
}

.bd-sidebar svg {
    position: relative;
    bottom: 2px;
}

button#probability-form-button,
button#impact-form-button {
    padding-left: 25px;
}
button#events-average-button::before {
    content: url(../../assets/chart-scattered.svg);
    padding-right: 9px;
    position: relative;
    right: 0px;
    width: 10px;
}
button#probability-form-button::before {
    @include btn-font-icon-config("\f682");
    right: 8px;
}
button#impact-form-button::before {
    @include btn-font-icon-config("\f681");
    right: 8px;
}
button#event-create-form-button::before {
    @include btn-font-icon-config("\f0fe");
    padding-right: 10px;
}
button#event-edit-form-button::before {
    @include btn-font-icon-config("\f044");
    padding-right: 10px;
}
button#event-edit-form-button::before {
    @include btn-font-icon-config("\f044");
    padding-right: 10px;
}
button#user-form-button::before {
    @include btn-font-icon-config("\f509");
    padding-right: 10px;
}

nav.bd-links button:nth-of-type(4)::before {
    @include btn-font-icon-config("\f273");
    padding-right: 10px;
}