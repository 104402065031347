.empty-container {
  min-height: 100%;
  width: 100%;

  & svg {
    font-size: 180px;
    color: darken($light-gray, 5%);
  }

  & p {
    color: darken($light-gray, 15%);
  }
}

.empty-container-2 {
  min-height: 37.1vh;

  & p {
    color: darken($light-gray, 15%);
  }
}

.dist-cxu-box {
  width: 100%;
  margin: 0;
  padding: 0 !important;
  height: 76vh;

  &--filled {
    @include border-radius(2px);
    border: 1px solid $primary;
  }
}

.dist-uxpae-box {
  padding: 0 0 0 10px !important;

  &--filled {
    @include border-radius(2px);
    margin-top: 20px;
    padding: 0;
    border: 1px solid $primary;
  }
}