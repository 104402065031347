.abc-input {
    padding: 0 20px;
    height: 55px;
    @include srl-border-radius(2px, all);
    @include font-size-and-weight(1rem, 300);

    &--date {
        @include font-source-sans(16px, false, $font-weight-slim);
        text-align: center;
        height: 43px !important;
    }
    &--date::placeholder{
        color: lighten($font-color-light, 45%) !important;
    }
    &--date:nth-child(1) {
        @include srl-border-radius(2px, top); 
        border-bottom: 0;
    }
    &--date:nth-child(2) {
        border-radius: 0;
    }
    &--date:nth-child(3) {
        @include srl-border-radius(2px, bottom);
        border-top: 0;
    }
    &--date:focus {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
    }
}

.abc-no-input {
    &::placeholder {
        color: lighten($font-color-light, 45%) !important;
        font-weight: $font-weight-slim;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        border: 1px solid #ced4da;
    }
}

