html {
    transition: 0.5s;
    height: 100vh;
    scroll-behavior: smooth;
}

div#root {
    padding-top: 25px;
}

@include media-breakpoint-down(hsm) { 
    .px-md-5 { 
        padding-left: 2rem !important;
        padding-right: 2rem !important; 
    } 
}
@include media-breakpoint-down(hmd) {
    .px-md-5 { 
        padding-left: 2rem !important;
        padding-right: 2rem !important; 
    } 
} 
