.hide-col {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.table-wrapper {
  height: 51.5vh;
  border-bottom: 1px solid rgb(235, 235, 235);
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-wrapper .table-row {
  &:hover {
    cursor: pointer;
    border-left: 4px solid darken($primary, 5%);
    background-color: lighten($darkNavy, 75%);
    padding-left: 5px !important;
  }
  
  &:focus {
    outline: 0;
    border-left: 9px solid darken($primary, 5%);
    padding-left: 0 !important;
  }

  &:active {
    background-color: lighten($darkNavy, 73%);
  }
}

.table-row {
  width: 100%;
  margin: 0;
  align-items: center;
  border: 1px solid #D6E3ED;
  text-align: center;
  height: auto;
  min-height: 48px;
  border-top: 0;
  transition: 0.2s;
  font-weight: 300;

  &:last-child {
    border-bottom: none !important;
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
    font-size: 13px;
  }
  @include media-breakpoint-down(md) { font-size: 13px; }
  @include media-breakpoint-down(hlg) { font-size: 14px; }
  @include media-breakpoint-down(hxl) { font-size: 16px; }

  &.header {
    width: 100%;
    margin: 0;
    background-color: #90a3c9;
    padding: 0 17px 0 0;
    font-family: "Open Sans", sans-serif;
    border: none;
  }

  &.title-header {
    width: 100%;
    margin: 0;
    padding: 16px 0;
    font-family: "Open Sans", sans-serif;
    border: none;
    border-radius: 2px 2px 0 0;

    & h3 {
      margin-bottom: 0;
      @include media-breakpoint-down(hsm) { font-size: 20px; }
      @include media-breakpoint-down(hmd) { font-size: 22px; }
      @include media-breakpoint-down(hlg) { font-size: 24px; }
      @include media-breakpoint-down(hxl) { font-size: 26px; }
    }

    @include media-breakpoint-down(sm) {
      background-color: white !important;
      color: black !important;
    }
  }

  & .col:first-child {
    width: 10%;
  }

}

.row-selected {
  background-color: #ebf0f5;
}


// User Admin Table
.user-admin-table {
  height: 200px;
  border-bottom: 1px solid rgb(235, 235, 235);
  overflow-x: hidden;
  overflow-y: scroll;
}
.user-admin-table .table-row {
  &:hover {
    border-left: 4px solid darken($primary, 5%);
    background-color: lighten($darkNavy, 75%);
    padding-left: 5px !important;
  }
  
  &:focus {
    outline: 0;
    border-left: 10px solid darken($primary, 5%);
    padding-left: 0 !important;
  }

  &:active {
    background-color: lighten($darkNavy, 73%);
  }
}

// User Amin Table Header
#user-form-modal .header {
  margin-top: 2rem;
  @include srl-border-radius(2px, top);
  max-width: 942px;
  padding: 0 20px 0 0;
}