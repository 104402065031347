.graphic {
    display: block !important;
    
    &--title {
        display: block !important;
        text-align: center;
        @include font-size-and-weight(24px, 600);
        width: 100%;
        & p {
            color: #8c8c8c !important;
            padding: 20px 0 0px 0;
            margin-bottom: 20px;
        }
    }

    &--wrapper {
        display: block !important;
        height: 100%;
    }
}