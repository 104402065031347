@import "~bootstrap/";

////////// Overrides Start Here! //////////
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1000px;
  }
};

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.close {
  z-index: 1;
}

.btn-primary {
  font-weight: 300;
}