.footer-nav {
    @include font-size-and-weight(10px, 200);

    & nav p {
        padding-left: 4px;
    }

    & p {
        float: left;
    }
}