.modal-header {
    background-color: $secondary;
    color: white;
    float: left;
    display: block;
    padding: 25px 3rem 15px 3rem;
    height: 120px;

    & p {
        margin-bottom: 0;
        padding-left: 30px;
    }

    & p:first-child {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 5px;
        float: left;
        width: 100%;
    }
    & p:nth-child(2) {
        float: left;
    }

    & .close {
        padding: 0 10px !important;
        margin: -1rem -1rem -1rem auto !important;
        position: relative !important;
        bottom: 30px;
        right: 0 !important;
        font-size: 40px !important;
        opacity: 1 !important;
        color: white;
        border-radius: 20px !important;
        transition: all .2s;

        &:hover {
            background-color: rgba(255, 0, 0, 0.461);
            width: 41px;
        }

        &:focus {
            outline: none;
        }
    }

    &--info {
        background-color: $light-gray;
        padding: 10px 0px;
    }

    &--types {
        border-left: 1px solid $secondary;
        border-left: 1px solid #16113F;
        padding-right: 3rem;
        & p {
            padding-bottom: 5px;
        }
    }
}

.modal-content {
    & h1 {
        font-size: 32px;
        padding-top: 2px;
        font-weight: bolder;
        padding-bottom: 10px;
    }
    & h4 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
    }
}

.modal-body p {
    line-height: 1.3;
}

.modal-footer {
    padding: 0.75rem 3rem 2rem 3rem;
    border-top: 0;
}

.modal-lg {
    max-width: 1250px;
}

// Event Create Modal
#event-create-form-modal .modal-header {
    height: 77px;
    padding: 20px 3rem 20px 3rem;
}
#event-create-form-modal .modal-header h1 {
    margin-bottom: 0;
}
#event-create-form-modal .modal-header .close {
    bottom: 30px;
}
#event-create-form-modal .modal-dialog {
    transform: translateY(13%);
}
#event-create-form-modal .modal-lg { width: 800px !important; } 

// Event Edit Modal
#event-edit-form-modal .modal-body {
    padding: 2rem 3rem 0 3rem;
}
#event-edit-form-modal .modal-header {
    height: 77px;
    padding: 20px 3rem 20px 3rem;
}
#event-edit-form-modal .modal-header h1 {
    margin-bottom: 0;
}
#event-edit-form-modal .modal-header .close {
    bottom: 30px;
}
#event-edit-form-modal .modal-dialog {
    transform: translateY(13%);
}
#event-edit-form-modal .modal-lg { width: 800px !important; } 

// Events Average Modal
#events-average-button-modal  .modal-body {
    padding: 2rem 3rem 0 3rem;
}
#events-average-button-modal .modal-header {
    height: 77px;
    padding: 20px 3rem 20px 3rem;
}
#events-average-button-modal .modal-header h1 {
    margin-bottom: 0;
}
#events-average-button-modal .modal-header .close {
    bottom: 30px;
}

#events-average-button-modal .modal-dialog {
    transform: translateY(6%);
}

// User Admin Modal
#user-form-modal .modal-header {
    height: 77px;
    padding: 20px 3rem 20px 3rem;
}
#user-form-modal .modal-header h1 {
    margin-bottom: 0;
}
#user-form-modal .modal-header .close {
    bottom: 30px;
}